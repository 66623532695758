var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"content"}},[_c('div',{class:{ stickyHeader: _vm.$vuetify.breakpoint.lgAndUp, stickyHeaderSmall: !_vm.$vuetify.breakpoint.lgAndUp }},[_c('v-row',{staticStyle:{"padding":"15px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',{staticStyle:{"margin-top":"-10px","font-size":"30px"},attrs:{"left":""}},[_vm._v(" far fa-question-circle ")]),_c('div',_vm._g({staticClass:"phase-left-header",on:{"click":function($event){_vm.help.displayed = true; _vm.help.page = 3}}},on),[_c('h2',[_vm._v("Phase n°3 en cours : Votes")]),_c('p',[_vm._v("Phase n°4 Délibération - à partir du "+_vm._s(_vm.end))])])]}}])},[_c('span',[_vm._v("Besoin d'aide sur la phase actuelle du concours ?")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-top":"3px"},attrs:{"icon":"","small":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.gotoNextCat(-1)}}},on),[_c('v-icon',[_vm._v("fas fa-chevron-left")])],1)]}}])},[_c('span',[_vm._v("Catégorie précédente")])]),(_vm.category)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey--text",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.category.title)+" ")])]}}],null,false,3735557027)},[_c('v-list',_vm._l((_vm.categories),function(cat){return _c('v-list-item',{key:cat.categoryId,on:{"click":function($event){return _vm.gotoCat(cat.categoryId)}}},[_c('v-list-item-title',[_vm._v(_vm._s(cat.title))])],1)}),1)],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-top":"3px"},attrs:{"icon":"","small":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.gotoNextCat(1)}}},on),[_c('v-icon',[_vm._v("fas fa-chevron-right")])],1)]}}])},[_c('span',[_vm._v("Catégorie suivante")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"phase-right-header",on:{"click":function($event){_vm.help.displayed = true; _vm.help.page = 4}}},[_c('h2',[_vm._v("Vos votes")]),(_vm.category && _vm.category.categoryId > 0)?_c('p',[_c('span',{style:({ color: _vm.totalVotes >= _vm.category.maxVotes / 2.0 && _vm.totalVotes <= _vm.category.maxVotes ? 'green' : 'red'})},[_vm._v(" "+_vm._s(_vm.totalVotes)+" / "+_vm._s(_vm.category.maxVotes)+" "),_c('i',{staticClass:"fas fa-star"})]),_vm._v("   "),_c('span',{style:({ color: _vm.totalTitleVotes >= 5 && _vm.totalTitleVotes <= 10 ? 'green' : 'red' })},[_vm._v(" "+_vm._s(_vm.totalTitleVotes)+" / 10 "),_c('i',{staticClass:"fas fa-feather-alt"})])]):_vm._e()])]}}])},[_c('span',[_vm._v("Besoin d'aide sur le déroulement du concours ?")])])],1),(_vm.isLoading)?_c('v-progress-linear',{staticStyle:{"position":"absolute","bottom":"-5px","left":"0","right":"0","height":"5px"},attrs:{"color":"accent","indeterminate":""}}):_vm._e()],1),(_vm.category && _vm.category.categoryId === 0)?_c('div',[_c('h2',{staticClass:"section"},[_vm._v(" Résumé de vos votes par catégorie ")]),_c('v-data-table',{staticStyle:{"margin":"25px"},attrs:{"headers":_vm.headers,"items":_vm.resume,"loading":_vm.waitingScreen,"disable-filtering":"","disable-sort":"","hide-default-footer":"","loading-text":"Mise à jours des données"},on:{"click:row":function($event){return _vm.gotoCat($event.categoryId)}},scopedSlots:_vm._u([{key:"item.votes",fn:function(ref){
var item = ref.item;
return [(item.categoryId > 0)?_c('span',{style:({ color: item.votes >= item.maxVotes / 2.0 && item.votes <= item.maxVotes ? 'green' : 'red'})},[_vm._v(" "+_vm._s(item.votes)+" / "+_vm._s(item.maxVotes)+" ")]):_c('b',[_vm._v(_vm._s(item.votes)+" / "+_vm._s(item.maxVotes))])]}},{key:"item.tvotes",fn:function(ref){
var item = ref.item;
return [(item.categoryId > 0)?_c('span',[_vm._v(" "+_vm._s(item.tvotes)+" ")]):_c('b',{style:({ color: item.tvotes >= 5 && item.tvotes <= 10 ? 'green' : 'red'})},[_vm._v(_vm._s(item.tvotes)+" / 10")])]}}],null,true)}),_c('h2',{staticClass:"section"},[_vm._v(" Les photos que vous avez sélectionnées pour le meilleur titre ")])],1):_vm._e(),_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.photosGalery),function(photo,index){return _c('v-flex',{key:photo.id,staticStyle:{"min-width":"250px","width":"15%","margin":"15px"}},[_c('div',[_c('div',{staticStyle:{"width":"250px","height":"250px","margin":"auto"}},[_c('div',{staticStyle:{"width":"250px","height":"250px","display":"table-cell","text-align":"center","vertical-align":"middle"}},[_c('img',{staticClass:"thumb",attrs:{"src":photo.thumb},on:{"click":function($event){return _vm.photosGalleryDisplay(index)}}})])]),_c('v-card',{staticClass:"card",staticStyle:{"margin-bottom":"50px"}},[_c('div',{staticClass:"thumb-title",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(photo.title)+" ")]),_c('div',{staticStyle:{"position":"absolute","bottom":"-17px","left":"0","right":"0","height":"30px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(photo.enableVotes)?_c('v-btn',_vm._g({staticStyle:{"opacity":"1","background":"#fff"},attrs:{"icon":"","small":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.vote(photo, 1)}}},on),[_c('v-icon',{style:({ color: photo.userVote > 0 ? '#ecce00' : '' })},[_vm._v(" fas fa-star ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(photo.userVote != 1 ? "Mettre 1 étoile sur cette photo" : "Annuler le vote 1 étoile pour cette photo"))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(photo.enableVotes)?_c('v-btn',_vm._g({staticStyle:{"opacity":"1","background":"#fff"},attrs:{"icon":"","small":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.vote(photo, 2)}}},on),[_c('v-icon',{style:({ color: photo.userVote > 1 ? '#ecce00' : '' })},[_vm._v(" fas fa-star ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(photo.userVote != 2 ? "Mettre 2 étoiles sur cette photo" : "Annuler le vote 2 étoiles pour cette photo"))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(photo.enableVotes)?_c('v-btn',_vm._g({staticStyle:{"opacity":"1","background":"#fff"},attrs:{"icon":"","small":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.vote(photo, -3)}}},on),[_c('v-icon',{style:({ color: photo.titleVote > 0 ? '#ecce00' : '' })},[_vm._v(" fas fa-feather-alt ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(photo.titleVote == 0 ? "Sélectionner la photo pour le meilleur titre" : "Retirer la photo de votre sélection pour le meilleur titre"))])])],1)])],1)])}),1)],1),_c('v-dialog',{attrs:{"width":"800px"},model:{value:(_vm.help.displayed),callback:function ($$v) {_vm.$set(_vm.help, "displayed", $$v)},expression:"help.displayed"}},[_c('v-card',[_c('v-card-title',{staticClass:"grey lighten-4"},[_vm._v(" Aide sur le déroulement du concours ")]),_c('Help',{attrs:{"selected-tab":_vm.help.page}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.help.displayed = false}}},[_vm._v(" Fermer ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }